@import './css/typography.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* common variables */
  --basic--primary: #B5DA1D;
  --basic--black: #000000;
  --basic--white: #FFFFFF;
  --basic--secondary: #919191;
  --basic--error: #E61E42;

  --background--header: #2D2B2B;
  --background--tab: #00000026;
  --background--popup: #121111;
  --background--page: linear-gradient(180deg, #2D2B2B 17.18%, #2D2D2D 100%);

  --line--header: #B5DA1D66;
  --line--divider: #B5DA1D33;
  --line--error: #FF214A66;

  --button--enabled: #333913;
  --button--active: #536116;
  --button--disabled: #222222;

  /* special items variables */
  --background--button--socials: 176.19deg, #B5DA1D 10.39%, #2D350C 124.83%;
  --background--rank-item: #33391380;
  --background--left-side-icon--completed: 168.27deg, #B5DA1D 8.59%, #60740F 123.05%;
  --background--wallet--balance: 242.63deg, #B8DB28 16.91%, #B5DA1D 35.04%, #60740F 104.13%;

  --line--toast--success: 270deg, #CDFF00 0%, #B5DA1D 33.33%, #4A5A05 66.67%, #2D2B2B 100%;

  --background--global-loader--first: 180deg, rgba(186, 222, 35, 0) 58.29%, rgba(100, 117, 26, 0.1479) 65.7%, rgba(18, 17, 17, 0.29) 73.1%, rgba(18, 17, 17, 0.67) 80.51%, #121111 87.91%;
  --background--global-loader--second: 270deg, #CEF527 0%, #ACCF20 36.92%, #849E16 95.38%;

  --background--rank-item--gold: 270deg, #121111 1.4%, #121111 26.05%, #121111 50.7%, #BE9916 75.35%, #FECC1D 100%;
  --background--rank-item--silver: 270deg, #121111 1.4%, #121111 26.05%, #121111 50.7%, #747474 75.35%, #AAAAAA 100%;
  --background--rank-item--bronze: 270deg, #121111 1.4%, #121111 26.05%, #121111 50.7%, #663614 75.35%, #995B30 100%;

  --background--own-rank-item--gold: 270deg, rgba(51, 57, 19, 0.5) 1.4%, rgba(51, 57, 19, 0.5) 26.05%, rgba(51, 57, 19, 0.5) 50.7%, #BE9916 75.35%, #FECC1D 100%;
  --background--own-rank-item--silver: 270deg, rgba(51, 57, 19, 0.5) 1.4%, rgba(51, 57, 19, 0.5) 26.05%, rgba(51, 57, 19, 0.5) 50.7%, #747474 75.35%, #AAAAAA 100%;
  --background--own-rank-item--bronze: 270deg, rgba(51, 57, 19, 0.5) 1.4%, rgba(51, 57, 19, 0.5) 26.05%, rgba(51, 57, 19, 0.5) 50.7%, #663614 75.35%, #995B30 100%;

  --box-shadow--footer: 0px 2px 60px 0px #B5DA1D33, 0px 0px 3px 0px #B5DA1D4D;
  --box-shadow--statistics-item-container: 0px 20px 50px 0px #00000099, 0px 0px 1px 0px #B5DA1D4D, 0px 30px 60px 0px #B5DA1D26 inset;
  --box-shadow--input: 0px 0px 5px 0px #B5DA1D99;
  --box-shadow--replenish-button-container: 0px 0px 17.26px 0px #70BAFFCC inset, 0px 0px 3.45px 0px #F2F6FA80 inset, 0px 4px 20px 0px #F5F5F580 inset;

  --page-wrapper--padding-bottom: 90px;
}

/* halloween theme */
.halloween-theme {
  /* common variables */
  --basic--primary: #FF6A1F;
  --button--enabled: #3F2003;
  --button--active: #6A3A0C;
  --line--header: #FF6A1F66;
  --line--divider: #FF6A1F66;

  /* special items variables */
  --background--rank-item: #3F200380;
  --background--button--socials: 176.19deg, #FF7C3A 10.39%, #AB3A01 124.83%;
  --background--wallet--balance: 261.3deg, #FFC800 -3.24%, #FF6A1F 44.29%, #D24600 91.82%;
  --background--left-side-icon--completed: 168.27deg, #FF7A37 8.59%, #A83901 123.05%;

  --line--toast--success: 270deg, #FFC400 0%, #FF6A1F 33.33%, #4E2A08 66.67%, #2D2B2B 100%;

  --background--global-loader--first: 180deg, rgba(255, 106, 31, 0) 58.29%, rgba(131, 83, 29, 0.15) 65.7%, rgba(18, 17, 17, 0.29) 73.1%, rgba(18, 17, 17, 0.67) 80.51%, #121111 87.91%;
  --background--global-loader--second: 270deg, #FFBB00 0%, #FF6A1F 37.44%, #E64D00 95.38%;
  
  --box-shadow--footer: 0px 2px 60px 0px #FF6A1F33, 0px 0px 3px 0px #FF6A1F4D;
  --box-shadow--statistics-item-container: 0px 20px 50px 0px #00000099, 0px 0px 1px 0px #FF6A1F4D, 0px 30px 60px 0px #FF6A1F26 inset;
  --box-shadow--input: 0px 0px 5px 0px #FF6A1F;
  --box-shadow--replenish-button-container: 0px 0px 24px 0px #FF0000CC inset, 0px 0px 3.45px 0px #FFB0B080 inset, 0px 4px 20px 0px #FF894D80 inset;
}

/* halloween theme */
.new-year-theme {
  /* common variables */
  --basic--primary: #01DBDB;
  --button--enabled: #043A3C;
  --button--active: #085E61;
  --line--header: #01DBDB66;
  --line--divider: #01DBDB66;
  /* special items variables */
  --background--rank-item: #043A3C;
  --background--button--socials: 176.19deg, #78FFFF 10.39%, #065657 124.83%;
  --background--wallet--balance: 270deg, #99F5FF 0%, #01DBDB 50%, #047676 100%;
  --background--left-side-icon--completed: 168.27deg, #56FCFC 8.59%, #0A8787 123.05%;

  --line--toast--success: 270deg, #99F5FF 0%, #01DBDB 33.33%, #047676 66.67%, #2D2B2B 100%;

  --background--global-loader--first: 180deg, rgba(255, 106, 31, 0) 58.29%, rgba(131, 83, 29, 0.15) 65.7%, rgba(18, 17, 17, 0.29) 73.1%, rgba(18, 17, 17, 0.67) 80.51%, #121111 87.91%;
  --background--global-loader--second: 270deg, #9AF5FF 0%, #01DBDB 49.36%, #029393 98.72%;

  --background--own-rank-item--gold: 270deg, rgba(4, 58, 60, 0.5) 1.4%, rgba(4, 58, 60, 0.5) 26.05%, rgba(4, 58, 60, 0.5) 50.7%, #BE9916 75.35%, #FECC1D 100%;
  --background--own-rank-item--silver: 270deg, rgba(4, 58, 60, 0.5) 1.4%, rgba(4, 58, 60, 0.5) 26.05%, rgba(4, 58, 60, 0.5) 50.7%, #747474 75.35%, #AAAAAA 100%;
  --background--own-rank-item--bronze: 270deg, rgba(4, 58, 60, 0.5) 1.4%, rgba(4, 58, 60, 0.5) 26.05%, rgba(4, 58, 60, 0.5) 50.7%, #663614 75.35%, #995B30 100%;
  
  --box-shadow--footer: 0px 2px 60px 0px #01DBDB33, 0px 0px 3px 0px #01DBDB4D;
  --box-shadow--statistics-item-container: 0px 20px 50px 0px #00000099, 0px 0px 1px 0px #01DBDB4D, 0px 30px 60px 0px #01DBDB26 inset;
  --box-shadow--input: 0px 0px 8px 0px #01DBDBCC;
  --box-shadow--replenish-button-container: 0px 0px 17.26px 0px #00E5EA inset, 0px 0px 3.45px 0px #00B6BA inset, 0px 4px 20px 0px #00979A80 inset;
  
}
/* changes svg to theme style. Add style .importantSvg to prevent changing  */
svg:not(.importantSvg) {
  stroke: var(--basic--primary);
  fill: var(--basic--primary);
}

.color--primary {
  color: var(--basic--primary);
}
.color--secondary {
  color: var(--basic--secondary);
}
.color--black {
  color: var(--basic--black);
}
.color--white {
  color: var(--basic--white);
}
.color--error {
  color: var(--basic--error);
}

.page-wrapper {
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--line--header);
  overflow: auto;
  background: var(--background--page);
  overflow-x: hidden;
  -ms-overflow-style: none; 
  scrollbar-width: none;  
  padding-bottom: var(--page-wrapper--padding-bottom);
}

.page-content::-webkit-scrollbar {
  display: none;
}

.page-content-wrapper {
  padding: 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  position: relative;
}

.flex-break-word {
  min-width: 1%; 
  word-wrap: break-word;
}

.break-word-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Toastify__toast--success svg {
  stroke: none;
  fill: var(--toastify-color-success);
}

.Toastify__toast--error svg {
  stroke: none;
  fill: var(--toastify-color-error);
}

.Toastify__close-button svg {
  stroke: none;
}

.Toastify__progress-bar-theme--dark {
  background: linear-gradient(var(--line--toast--success)) !important;
}

.Toastify__progress-bar--error {
  background: linear-gradient(270deg, #FF214A 0%, #DA1D1D 33.33%, #5A0505 66.67%, #2D2B2B 100%) !important;
}

.swiper-slide {
  width: 50vw;
}

.swiper-wrapper {
  padding-top: 20px;
  height: 225px !important;
}

.swiper-pagination-horizontal {
  bottom: 40px !important;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 30px !important;
  border: 1px solid var(--basic--primary);
  opacity: 1 !important;
  background: none !important;
}

.swiper-pagination-bullet-active {
  width: 48px !important;
  height: 10px;
  border-radius: 30px !important;
  background: var(--basic--primary) !important;
  border: 1px solid var(--basic--primary);
  opacity: 0;
}