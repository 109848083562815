.friends__list {
  width: 100%;
}

.ref_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--background--rank-item);
  border-radius: 25px;
  border: 1px solid var(--line--divider);
  padding: 12px 16px;
}

.buttons__container {
  display: flex; 
  gap: 12px; 
  width: 100%;
  position: sticky;
  bottom: 12px;
  left: 0;
}