.transaction_info__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.transaction_info__item {
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
}

.transaction_info__link {
  text-decoration-color: var(--basic--primary);
}