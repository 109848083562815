.friend_item__wrapper {
  width: 100%;
}

.friend__image {
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

.friend_info__wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.friend_info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.friend_item {
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.friend_score__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: fit-content;
}