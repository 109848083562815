.task_item__wrapper {
  width: 100%;
}

.task_item {
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  background: var(--background--popup);
}

.task_score__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: fit-content;
}

.task_info__wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.task_main_info__wrapper {
  display: flex;
  width: 100%;
  gap: 2px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.task_complete__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
}