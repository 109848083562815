.rank_item__wrapper {
  display: flex; 
  gap: 16px; 
  width: 100%;
  position: sticky;
  bottom: 12px;
  left: 0;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--line--header);
  border-radius: 25px;
  background: var(--background--rank-item);
  backdrop-filter: blur(8px);
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.hidden {
  opacity: 0;
}

.rank_score__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: fit-content;
}

.rank_item__image {
  border-radius: 50px;
  height: 40px;
  width: 40px;
}