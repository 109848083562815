.dialog__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.info__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.amount__wrapper{
  width: 220px;
  height: 220px;
  // border: 1px solid var(--basic--primary);
  // border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}