@media (hover: hover) {
  button:hover {
    background-color: var(--button--active);
  }
}

.button {
  min-height: 56px;
  min-width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--basic--primary);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  background-color: var(--button--enabled);
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.button__disabled {
  color: var(--basic--secondary);
  background: var(--button--disabled);
  pointer-events: none;
}