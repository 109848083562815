@keyframes appearance {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5000;
  overflow-x: hidden;
  overflow-y: auto;
  backdrop-filter: blur(8px);
  animation: appearance 0.7s;
}

.dialog {
  width: calc(100% - 32px);
  padding: 20px;
  box-shadow: 0px 2px 60px 0px var(--line--divider),
  0px 0px 3px 0px var(--line--divider);
  background: var(--background--popup);
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 5002;
  border-radius: 25px;
}

.dialog__backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5001;
  cursor: pointer;
}

.dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog__body {
  max-height: 80vh;
  overflow: auto;
}