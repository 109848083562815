.wrapper {
  padding: 30px 16px;
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: var(--background--rank-item);
  }
}

.active {
  background: var(--background--rank-item);
}