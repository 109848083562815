.wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

// .loader {
//   display: flex;
//   flex-direction: column;
//   gap: 6px;
//   justify-content: center;
//   align-items: center;
// }