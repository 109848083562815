.transaction_item__wrapper {
  width: 100%;
  cursor: pointer;
}

.transaction_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
}

.left_icon__container {
  position: relative;
}

.left_subicon_container {
  position: absolute;
  top: -5px;
  right: -5px;
}

.transaction_info__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.transaction_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction_date {
  padding: 0 8px;
  border: 1px solid var(--basic--primary);
  border-radius: 40px;
  text-transform: lowercase;
}