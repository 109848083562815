.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background--popup);
  border-radius: 25px;
  border: 1px solid var(--line--divider);
  overflow: hidden;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}