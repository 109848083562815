.dialog__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ul {
    padding-left: 25px;
    li::marker {
      color: white;
    }
  }
}