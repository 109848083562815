.rank_item__wrapper {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.rank_own_item__wrapper {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  background: var(--background--rank-item);
}

.rank_score__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: fit-content;
}

.rank_item__image {
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

.position_1 {
  background: linear-gradient(var(--background--rank-item--gold));
}
.position_2 {
  background: linear-gradient(var(--background--rank-item--silver));
}
.position_3 {
  background: linear-gradient(var(--background--rank-item--bronze));
}

.rank_own_item__wrapper.position_1 {
  background: linear-gradient(var(--background--own-rank-item--gold));
}
.rank_own_item__wrapper.position_2 {
  background: linear-gradient(var(--background--own-rank-item--silver));
}
.rank_own_item__wrapper.position_3 {
  background: linear-gradient(var(--background--own-rank-item--bronze));
}