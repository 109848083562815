.icon_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40;
  min-height: 40;
  border: 1.5px solid var(--basic--white);
  border-radius: 100px;
}

.subicon_container {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: linear-gradient(var(--background--left-side-icon--completed));
  display: flex;
  justify-content: center;
  align-items: center;
}

.premium {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 52px;
  height: 16px;
  padding: 2px 0px 2px 0px;
  border-radius: 40px 40px 40px 0px;
  background: var(--basic--white);
  display: flex;
  justify-content: center;
  transform: translate(-50%, 50%);
}