.wallet_address__container {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  background: var(--background--popup);
  gap: 12px;
}

.wallet_address {
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
}

.wallet_address__buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: flex-start;
}