.container__wrapper {
  position: absolute;
  left: 0;
  display: flex;
  width: 100%;
}

.container_box_fill {
  height: 150px;
  border-bottom: 1px solid var(--line--header);
  border-top: 1px solid var(--line--header);
  background: linear-gradient(180deg, #2D2B2B 5.92%, rgba(45, 43, 43, 0) 147.34%);
  width: 100%;
  backdrop-filter: blur(8px);
}

.replenish_button_container {
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: var(--box-shadow--replenish-button-container);
  top: 110px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #010C15;
  cursor: pointer;
}

.info_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  top: 90px;
  z-index: 1000;
}

.tree_score__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: fit-content;
}

.side_info_container__left {
  @extend .info_container;
  left: 10%
}

.side_info_container__right {
  @extend .info_container;
  right: 10%
}

.center_info_container {
  @extend .info_container;
  width: 100%;
  gap: 6px;
  top: 12px;
  left: 50%;
  transform: translate(-50%, 0);
}