.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background--popup);
  border-radius: 25px;
  overflow: hidden;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.title__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
