.button {
  min-height: 44px;
  min-width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--basic--primary);
  background: linear-gradient(var(--background--button--socials));
  border-radius: 100px;
}

.button__disabled {
  color: var(--basic--secondary);
  background: var(--button--disabled);
  pointer-events: none;
}