.input__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input__container {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 14px 16px;
  border: 1px solid var(--line--header);
  background: var(--background--popup);
  border-radius: 15px;
  &:focus-within {
    box-shadow: var(--box-shadow--input);
    -webkit-transition: box-shadow 0.3s ease-out;
    -moz-transition: box-shadow 0.3s ease-out;
    transition: box-shadow 0.3s ease-out;
  }
  &__error {
    border: 1px solid var(--line--error);
    box-shadow: 0px 0px 5px 0px var(--basic--error);
  }
}

.input {
  width: 100%;
  background: var(--background--popup);
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.4px;
  text-align: left;
  color: white;
  &::placeholder {
    color: #919191;
  }
  &:focus {
    outline: none;
    border: none;
  };
}

.icon__container {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

