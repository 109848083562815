.footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 24px;
  display: flex;
  justify-content: center;
}

.controls {
  display: flex;
  width: calc(100% - 32px);
  padding: 10px 0;
  border-radius: 2rem;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  background: #00000026;
  a {
    text-decoration: none;
  }
  box-shadow: var(--box-shadow--footer);
  backdrop-filter: blur(8px);
}

.control__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.5px;
}