.title-large {
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}

.title1 {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}

.title2 {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
}

.title3 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.subheadline1 {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.subheadline2 {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.body1 {
  font-family: 'Lato', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.4px;
  text-align: left;
}

.caption1 {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  letter-spacing: 0.5px;
  text-align: left;
}

.caption2 {
  font-family: 'Lato', sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: left;
}